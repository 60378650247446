<script>
import { VclCode } from 'vue-content-loading';
import {mapGetters, mapState} from "vuex";

export default {
  components: {
    VclCode
  },
  props: {

  },
  computed: {
    ...mapGetters([
      'getPersonaName',
      'getRoles'
    ])
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/dashboard/agent', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.agent = data;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  },
  created() {
    this.getData()
    this.timer = setInterval(() => {this.getData();}, 1000 * 30);
  },
  destroyed() {
    if(this.timer) clearInterval(this.timer);
  },
  data() {
    return {
      ready: false,
      error: false,
      agent: {},
      timer: null
    }
  }
};
</script>

<template>
  <div v-if="ready">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <div class="media">
                  <div class="media-body align-self-center">
                    <div class="text-muted">
                      <h5 class="mb-1">{{ getPersonaName() }}</h5>
                      <p class="mb-0">
                        <template v-for="role in getRoles()">
                          <span class="badge badge-primary text-black mr-1" :key="role">
                            {{ role }}
                          </span>
                        </template>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 align-self-center">
                <div class="text-lg-center mt-4 mt-lg-0">
                  <div class="row">
                    <div class="col-4">
                      <div>
                        <p class="text-muted text-truncate mb-2">
                          Pending Tickets
                        </p>
                        <h3 class="mb-0" :class="{'text-success': agent.helpdesk.pending_response === 0, 'text-warning': agent.helpdesk.pending_response !== 0}">
                          {{ $n(agent.helpdesk.pending_response) }}
                        </h3>
                      </div>
                    </div>
                    <div class="col-4">
                      <div>
                        <p class="text-muted text-truncate mb-2">
                          Assigned Tickets
                        </p>
                        <h3 class="mb-0">
                          {{ $n(agent.helpdesk.assigned) }}
                        </h3>
                      </div>
                    </div>
                    <div class="col-4">
                      <div>
                        <p class="text-muted text-truncate mb-2">
                          Open Tickets
                        </p>
                        <h3 class="mb-0">
                          {{ $n(agent.helpdesk.open) }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="error">
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card border border-danger">
          <div class="card-header bg-transparent border-danger">
            <h5 class="my-0 text-danger">
              <i class="far fa-exclamation-circle mr-3"></i> {{ $t('error.server.generic.title') }}
            </h5>
          </div>
          <div class="card-body">
            <h5 class="card-title mt-0">
              {{ $t('dashboard.error.info') }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <vcl-code :height="90"></vcl-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
